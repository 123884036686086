<template>
    <div class="container">
        <div class="title">
            2019年10月10日账单详情
            <el-button type="primary" style="float:right">打印</el-button>
        </div>
        <div class="describe">
            <p>商城名称:freestyle商城</p>
            <p>账单时间:2019年10月10日00:00:00至2019年10月10日23:59:59</p>
        </div>
        <div class="statistics">
            <el-row :gutter="20">
                <el-col :span="6">
                    <div class="grid-content bg-purple">
                        <p class="s-title">期初余额(元)</p>
                        <p class="s-data">25641.00</p>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="grid-content bg-purple">
                        <p class="s-title">本期收入(元)</p>
                        <p class="s-data">25641.00</p>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="grid-content bg-purple">
                        <p class="s-title">本期支出(元)</p>
                        <p class="s-data">25641.00</p>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="grid-content bg-purple">
                        <p class="s-title">期末余额(元)</p>
                        <p class="s-data">25641.00</p>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="dataTable">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-table>
                        <el-table-column label="收入统计"></el-table-column>
                        <el-table-column label></el-table-column>
                        <el-table-column label></el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="12">
                    <el-table>
                        <el-table-column label="支出统计"></el-table-column>
                        <el-table-column label></el-table-column>
                        <el-table-column label></el-table-column>
                    </el-table>
                </el-col>
            </el-row>
        </div>
        <div>
            <p class="tongji">储值统计</p>
            <div class="statistics">
                <el-row :gutter="20">
                    <el-col :span="6">
                        <div class="grid-content bg-purple">
                            <p class="s-title">期初余额(元)</p>
                            <p class="s-data">25641.00</p>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content bg-purple">
                            <p class="s-title">本期收入(元)</p>
                            <p class="s-data">25641.00</p>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content bg-purple">
                            <p class="s-title">本期支出(元)</p>
                            <p class="s-data">25641.00</p>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content bg-purple">
                            <p class="s-title">期末余额(元)</p>
                            <p class="s-data">25641.00</p>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div>
            <p class="tongji">积分统计</p>
            <div class="statistics">
                <el-row :gutter="20">
                    <el-col :span="6">
                        <div class="grid-content bg-purple">
                            <p class="s-title">期初余额(积分)</p>
                            <p class="s-data">25641.00</p>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content bg-purple">
                            <p class="s-title">本期产生(积分)</p>
                            <p class="s-data">25641.00</p>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content bg-purple">
                            <p class="s-title">本期消耗(积分)</p>
                            <p class="s-data">25641.00</p>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content bg-purple">
                            <p class="s-title">期末余额(积分)</p>
                            <p class="s-data">25641.00</p>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
    export default {};
</script>

<style lang="scss" scoped>
    .title {
        text-align: center;
    }

    .describe {
        margin-top: 20px;
        font-size: 15px;
        color: #666;
        overflow: hidden;

        p:nth-child(1) {
            float: left;
        }

        p:nth-child(2) {
            float: right;
        }
    }

    .statistics {
        background: #f2f2f2;
        border-radius: 5px;
        text-align: center;
        padding: 30px 0;
        margin-top: 20px;

        .s-title {
            font-size: 13px;
        }

        .s-data {
            font-size: 18px;
            color: #000;
            font-weight: 500;
            padding-top: 5px;
        }
    }

    .dataTable {
        margin-top: 20px;
    }

    .tongji {
        margin-top: 20px;
        color: #999;
        font-size: 18px;
    }
</style>